// Vendors
import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import app from '@/router/routes/app';
import profile from '@/router/routes/profile';
import reports from '@/router/routes/reports';
import settings from '@/router/routes/settings';
import smartAlerts from '@/router/routes/smart-alerts';
import subscriptions from '@/router/routes/subscriptions';

/**
 * Create an instance of Vue Router
 * and configure the Columbus routes
 *
 * @returns {VueRouter}
 */
export default function routerInstance() {
  Vue.use(VueRouter);

  return new VueRouter({
    mode: 'history',
    routes: [
      ...app,
      ...profile,
      ...reports,
      ...settings,
      ...smartAlerts,
      ...subscriptions,
    ],
  });
}
