import endpoints from '@/middleware/api/utils/endpoints';
import {
  delayExecution, safeGet, safeRequest, constructBeneficiariesEndpoint,
} from './helpers';

const {
  users, userDetails, timeline, invoice, payAllInvoices, catalog,
  subsPlan, main, subsPlanSuffix, latestPayments,
  subscriptions, quota,
} = endpoints.subscriptions;
const BENEFICIARY_V1 = 'application/vnd.cvo.subs.beneficiary-v1+json';

// API Methods
async function getUsers(payload, { token, userInfo }) {
  return delayExecution(() => safeGet(`${users}${encodeURIComponent(payload)}`, token, userInfo));
}

async function getLatestAccounts({ token, userInfo }) {
  return safeGet(userDetails, token, userInfo);
}

async function getLatestPayments({ token, userInfo }) {
  return safeGet(latestPayments, token, userInfo);
}

async function getUserDetails(payload, { token, userInfo }) {
  return safeGet(`${userDetails}${payload}${timeline}`, token, userInfo);
}

async function getListBeneficiaries({ token, userInfo }, payload) {
  return safeGet(constructBeneficiariesEndpoint(payload), token, userInfo);
}

async function saveBeneficiary({ token, userInfo }, payload) {
  return safeRequest(
    'post',
    constructBeneficiariesEndpoint(payload),
    token,
    payload.beneficiary,
    { 'Content-Type': BENEFICIARY_V1 },
    userInfo,
  );
}

async function saveEditedBeneficiary({ token, userInfo }, payload) {
  return safeRequest(
    'put',
    constructBeneficiariesEndpoint({ ...payload, beneficiaryId: payload.beneficiaryId }),
    token,
    payload.beneficiary,
    { 'Content-Type': BENEFICIARY_V1 },
    userInfo,
  );
}

async function removeBeneficiary({ token, userInfo }, payload) {
  return safeRequest(
    'delete',
    constructBeneficiariesEndpoint({ ...payload, beneficiaryId: payload.beneficiaryId }),
    token,
    {},
    { 'Content-Type': BENEFICIARY_V1 },
    userInfo,
  );
}

async function getInvoiceDetails(payload, { token, userInfo }) {
  return safeGet(`${invoice}${payload}`, token, userInfo);
}

async function triggerAllInvoicesPayment(payload, { token, userInfo }) {
  return safeRequest('post', `${userDetails}${payload}${payAllInvoices}`, token, {}, {}, userInfo);
}

async function getCatalog({ token, userInfo }) {
  return safeGet(catalog, token, userInfo);
}

async function createSubsPlan({ token, userInfo }, payload) {
  const { userId } = payload[0];
  return safeRequest(
    'post',
    `${main}${subsPlan}/${userId}${subsPlanSuffix}`,
    token,
    payload[0],
    {},
    userInfo,
  );
}

async function changeSubsPlan({ token, userInfo }, payload) {
  const { userId, bundleId } = payload[0];
  const url = `${main}${subsPlan}/${userId}${main}/${bundleId}`;
  return safeRequest(
    'put',
    url,
    token,
    payload[0],
    {},
    userInfo,
  );
}

async function changeSubsQuota({ token, userInfo }, payload) {
  const { subscriberId, subscriptionId } = payload[1];
  return safeRequest(
    'put',
    `${userDetails}${subscriberId}${subscriptions}${subscriptionId}${quota}`,
    token,
    payload[0],
    {},
    userInfo,
  );
}

async function cancelSubsPlan({ token, userInfo }, payload) {
  const url = `${main}${subsPlan}/${payload.userId}${main}/${payload.bundleId}`;
  return safeRequest(
    'delete',
    url,
    token,
    {},
    {},
    userInfo,
  );
}

export default {
  getListBeneficiaries,
  getUsers,
  getUserDetails,
  getInvoiceDetails,
  triggerAllInvoicesPayment,
  getCatalog,
  createSubsPlan,
  changeSubsPlan,
  changeSubsQuota,
  cancelSubsPlan,
  getLatestAccounts,
  getLatestPayments,
  removeBeneficiary,
  saveBeneficiary,
  saveEditedBeneficiary,
};
