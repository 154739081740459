<template>
  <v-breadcrumbs
    v-if="breadcrumbsItems.length > 0"
    :items="breadcrumbsItems"
    divider=">"
    class="pa-0 mt-4 mb-10"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        exact
      >
        {{ $t(item.i18n) }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  name: 'Breadcrumbs',

  computed: {
    breadcrumbsItems() {
      const items = typeof this.$route.meta.breadCrumb === 'function'
        ? this.$route.meta.breadcrumbs.call(this, this.$route)
        : this.$route.meta.breadcrumbs;
      return items || [];
    },
  },
};
</script>
