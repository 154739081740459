import configuration from './configuration';
import userManagement from './user-management';
import promos from './promos';
import catalogs from './catalogs';

export default {
  subscriptions: {
    configuration,
    userManagement,
    promos,
    catalogs,
  },
};
