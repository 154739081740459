import alerts from './alerts';
import app from './app';
import cms from './cms';
import logs from './logs';
import masterdata from './masterdata';
import profile from './profile';
import reports from './reports';
import subscriptions from './subscriptions';
import users from './users';

export default ({
  ...alerts,
  ...app,
  ...cms,
  ...logs,
  ...masterdata,
  ...profile,
  ...reports,
  ...subscriptions,
  ...users,
});
