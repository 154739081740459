<template>
  <Error
    :icon="$t('app.icons.error')"
    :title="$t('app.error.error500.title')"
    :subheading="$t('app.error.error500.subheading')"
  >
    <template #extra>
      <p class="text-subtitle-2 font-italic">
        {{ message }}
      </p>
      <p class="text-body-1">
        {{ $t('app.error.error500.advice') }}
      </p>
      <v-btn
        outlined
        small
        @click="logout"
      >
        <v-icon left>
          {{ $t('app.icons.power') }}
        </v-icon>
        {{ $t('app.error.error500.action') }}
      </v-btn>
    </template>
  </Error>
</template>

<script>
import auth from '@/middleware/auth';
import Error from '@/components/app/error/error.vue';

export default {
  name: 'Error500',

  components: {
    Error,
  },

  props: {
    message: {
      type: String,
      required: true,
      default: '',
    },
  },

  methods: {
    logout() {
      auth.then((client) => client.logout());
    },
  },
};

</script>
