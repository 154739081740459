export default {
  ENV: {
    PRODUCTION: 'production',
    STAGING: 'staging',
    DEVELOPMENT: 'development',
  },
  AUTH: {
    EXPIRE_AT_SESSION: 'expireAtSession',
    REDIRECT_AFTER_LOGIN: 'redirectAfterLogin',
    OLD_SESSION_KEYS: {
      ID_TOKEN: 'id_token',
      EXPIRES_AT: 'expires_at',
      ACCESS_TOKEN: 'access_token',
      ACTIVE_SESSION: 'activeSession',
    },
  },
  DRIFT: {
    PRODUCTION: 'd9czk8tra32w',
    DEVELOPMENT: 'hmcys93v629x',
  },
  TENANT: {
    '4U': '4U',
    '0B': '0B',
    '4R': '4R',
    AM: 'AM',
    AS: 'AS',
    AV: 'AV',
    AXA: 'AXA',
    CVO: 'CVO',
    D7: 'D7',
    FA: 'FA',
    F3: 'F3',
    JA: 'JA',
    HR: 'HR',
    J9: 'J9',
    /* @Deprecated('https://caravelo.atlassian.net/browse/CVO-15760') KZR: 'KZR', */
    SV: 'SV',
    LA: 'LA',
    MN: 'MN',
    TGG: 'TGG',
    TP: 'TP',
    TZ: 'TZ',
    Y4: 'Y4',
    VH: 'VH',
    WO: 'WO',
    W6: 'W6',
    XJ: 'XJ',
    EDO: 'EDO',
    XY: 'XY',
    DEFAULT: 'DEFAULT',
  },
  THEME: {
    '4U': {
      primary: '#089BC9',
    },
    '0B': {
      primary: '#00C0F3',
    },
    '4R': {
      primary: '#004295',
    },
    AM: {
      primary: '#163152',
    },
    AS: {
      primary: '#01426A',
    },
    AV: {
      primary: '#ED0707',
    },
    AXA: {
      primary: '#00008F',
    },
    CVO: {
      primary: '#004295',
    },
    D7: {
      primary: '#E10000',
    },
    FA: {
      primary: '#1A94DA',
    },
    F3: {
      primary: '#522D6D',
    },
    JA: {
      primary: '#004295',
    },
    HR: {
      primary: '#235183',
    },
    J9: {
      primary: '#004A97',
    },
    /*
    @Deprecated('https://caravelo.atlassian.net/browse/CVO-15760')
    KZR: {
      primary: '#001E62',
    },
    */
    SV: {
      primary: '#009DFF',
    },
    LA: {
      primary: '#1B0088',
    },
    MN: {
      primary: '#8BC63E',
    },
    TGG: {
      primary: '#F8A736',
    },
    TP: {
      primary: '#C2000F',
    },
    TZ: {
      primary: '#333333',
    },
    Y4: {
      primary: '#333333',
    },
    VH: {
      primary: '#008EAA',
    },
    WO: {
      primary: '#DE1993',
    },
    W6: {
      primary: '#D10084',
    },
    EDO: {
      primary: '#003399',
    },
    XY: {
      primary: '#00BCB4',
    },
    DEFAULT: {
      primary: '#999999',
    },
  },
  PRODUCTS: {
    UPSELLS: 'up',
    CHATBOT: 'bot',
    SUBSCRIPTIONS: 'subs',
    COUPONPASS: 'cp',
    EXCHANGE: 'xc',
    UPGRADES: 'upg',
    EXTRA_SEATS: 'xs',
    GIFT_VOUCHER: 'gv',
    VPASS: 'vpass',
    VCLUB: 'vclub',
    DASHBOARDS: 'dashboards',
  },
  SECTIONS: {
    REPORTS: 'reports',
    DASHBOARDS: 'dashboards',
  },
  HEADERS: {
    TOTAL_PAGES: 'total-pages',
  },
  PAGES: {
    HOME: 'home',
  },
  /** Roles stored on Auth0 */
  ROLES: {
    SUPER: 'super_admin',
    ADMIN: 'admin',
    CREATOR: 'creator',
    VIEWER: 'viewer',
    SUPERVISOR: 'supervisor',
  },
  STATUS: {
    OK: 'OK',
    ERROR: 'ERROR',
    ALERTS: 'ALERTS',
    NOT_ALLOWED_ACTION: 'NOT_ALLOWED_ACTION',
  },
  EVENTS: {
    CLOSE_DIALOG: 'close-dialog',
    CLEAR_SEARCH: 'clear-search',
    SUBMIT_SEARCH: 'submit-search',
    PAGINATION_BACK: 'pagination-back',
    PAGINATION_NEXT: 'pagination-next',
    COUNTDOWN_FINISH: 'countdown-finish',
    UPDATE_TIMEFRAME: 'update-timeframe',
    CONFIRM_DIALOG_ACTION: 'confirm-dialog-action',
    SELECTION_CHANGED: 'selection-changed',
    CLOSE: 'close',
    UPDATED: 'updated',
    NEXT: 'next',
    PREVIOUS: 'previous',
    CHANGE: 'change',
    SUBMIT: 'submit',
    CANCEL: 'cancel',
    VUE_UPDATE: 'update:',
    SHOW_DIALOG: 'show-dialog',
  },
  DIALOG: {
    APP: {
      IDLE: 'app-idle',
    },
    SETTINGS: {
      GROUP: {
        REMOVE: 'settings-group-remove',
        CANCEL: {
          EDIT: 'settings-group-cancel-edit',
          CREATE: 'settings-group-cancel-create',
        },
      },
      ROUTE: {
        REMOVE: 'settings-route-remove',
        CANCEL: {
          EDIT: 'settings-route-cancel-edit',
          CREATE: 'settings-route-cancel-create',
        },
      },
    },
  },
  FILE_FORMATS: {
    CSV: 'csv',
    JSON: 'json',
    ZIP: 'zip',
  },
  SIDE_NAVBAR: {
    EVENTS: {
      MINI_UPDATED: 'mini-updated',
    },
    MENU: {
      ALERTS: 'alerts',
      LOGOUT: 'logout',
      TOGGLE: 'toggle',
      PROFILE: 'profile',
      REPORTS: 'reports',
      DASHBOARDS: 'dashboards',
      SETTINGS: 'settings',
      SEARCH: 'search',
      BOT_SETTINGS: 'chatbot_settings',
    },
  },
  IATA_CODES: {
    MAX_LENGTH: 3,
  },
  KENDO: {
    GRID_TD: 'td',
    GRID_GROUP_HEADER: 'groupHeader',
  },
  FEATURES: {
    PROMOS: 'promos',
  },
  DATE_FORMAT: {
    ISO_DATE: 'yyyy-MM-dd',
    STRING_DATE: 'E, MMM dd, yyyy',
    STRING_TIME: 'HH:mm (OOOO)',
  },
  SUBS_PRODUCTS: {
    SUBSCRIPTION: 'subscription',
    CLUB: 'club',
    UNLIMITED: 'unlimited',
  },
};
